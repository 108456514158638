import * as moment from "moment/moment";

import { Container, Draggable } from "vue-smooth-dnd";
import { mapActions, mapGetters, mapMutations } from "vuex";
import icons from "../../mixins/icons/index";
// import { applyDrag } from './utils'
const scene = {
  type: "container",
  props: {
    orientation: "horizontal",
  },
};
export default {
  name: "Cards",
  components: { Container, Draggable },
  props: ["statusIdList"],
  mixins: [icons],
  data() {
    return {
      scene,
      upperDropPlaceholderOptions: {
        className: "cards-drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      dropColumn: -1,
      deliveryType: [],
      combineList: [],
      firstCombine: [],
      orderPayload: {},
      isMobile: false,
      listFor: [
        {
          id: 1,
          text: "1",
        },
        {
          id: 2,
          text: "2",
        },
        {
          id: 3,
          text: "3",
        },
        {
          id: 4,
          text: "4",
        },
        {
          id: 5,
          text: "5",
        },
        {
          id: 6,
          text: "6",
        },
        {
          id: 7,
          text: "7",
        },
        {
          id: 8,
          text: "8",
        },
        {
          id: 9,
          text: "9",
        },
        {
          id: 10,
          text: "10",
        },
        {
          id: 11,
          text: "11",
        },
        {
          id: 12,
          text: "12",
        },
        {
          id: 13,
          text: "13",
        },
        {
          id: 14,
          text: "14",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      shipmentMethods: "orders/shipmentMethods",
      paymentMethods: "orders/paymentMethods",
      users: "contacts/users",
      profile: "profile/profile",
      combineOrders: "config/combineOrders",
      combineCheckedList: "config/combineCheckedList",
      beginCombineOrders: "config/beginCombineOrders",
      searchQuery: "orders/searchQuery",
      orderStatusList: "orders/orderStatusList",
      order: "orders/order",
    }),
  },
  created() {
    this.getShipmentMethods().then(() => {
      this.shipmentMethods.data.map((item) => {
        this.deliveryType.push(item);
        this.$forceUpdate();
      });
    });
  },
  watch: {
    beginCombineOrders(e) {
      if (e) {
        this.getCombineOrders();
      }
    },
  },
  methods: {
    onScroll(column, { target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 50 >= scrollHeight) {
        if (this.checkPagination(column)) {
          this.showMoreOrders(column);
        }
      }
    },
    getCombineOrders() {
      this.combineList = [];
      this.statusIdList.map((item) => {
        const choiceList = item.list;
        if (choiceList.length) {
          choiceList.map((itemStatus) => {
            if (this.combineCheckedList.includes(itemStatus.id)) {
              this.combineList.push(itemStatus);
            }
          });
        }
      });
      this.firstCombine = this.combineList.filter(
        (item) => item.id === this.combineCheckedList[0]
      )[0];
      const orderProductList = [];
      this.combineList.map((itemOrder) => {
        itemOrder.order_products.map((itemOrderProduct) => {
          if (
            orderProductList.some(
              (item) => item.product_id === itemOrderProduct.product_id
            )
          ) {
            orderProductList.map((orderListItem) => {
              if (orderListItem.product_id === itemOrderProduct.product_id) {
                orderListItem.quantity =
                  itemOrderProduct.quantity + orderListItem.quantity;
              }
            });
          } else {
            orderProductList.push(itemOrderProduct);
          }
        });
      });
      const firstIndex = this.combineList.indexOf(this.firstCombine);
      this.combineList.splice(firstIndex, 1);
      this.combineProductInOrders(orderProductList);
    },
    combineProductInOrders(orderProductList) {
      this.showOrder(this.firstCombine.id).then((res) => {
        if (res.status) {
          this.firstCombine = res.data;
          this.firstCombine.products = orderProductList;
          this.editOrder({
            id: this.firstCombine.id,
            data: this.firstCombine,
          });
          const listId = [];
          this.combineList.map((item) => {
            listId.push({ id: item.id, price: item.total_cost });
          });
          this.changeOrdersStatus({
            order_status_id: 48,
            orders: listId,
          }).then((res) => {
            if (res.status) {
              this.getOrdersList(this.searchQuery);
            } else {
              this.$toasted.error(JSON.stringify(res.data));
            }
          });
          this.getOrdersList(this.searchQuery);
          this.clearCombineOrders();
          this.confirmCombineOrders(false);
          this.toggleCombineOrders(false);
        }
      });
    },
    getShipmentMethod(id) {
      if (this.deliveryType.filter((item) => item.id === id)[0]) {
        return this.deliveryType.filter((item) => item.id === id)[0].title;
      } else {
        return "-";
      }
    },
    getPaymentMethod(id) {
      if (this.paymentMethods.data.filter((item) => item.id === id)[0]) {
        return this.paymentMethods.data.filter((item) => item.id === id)[0]
          .title;
      } else {
        return "-";
      }
    },
    checkPagination(column) {
      if (
        column.total > column.per_page &&
        column.list.length >= column.per_page
      ) {
        return true;
      }
    },
    showMoreOrders(column) {
      this.orderPayload = {
        order_status_id: column.id,
        per_page: column.per_page + 10,
      };
      if (this.searchQuery) {
        this.orderPayload[this.searchQuery.title] = this.searchQuery.id;
      }
      column.per_page = column.per_page + 10;
      this.getOrdersList(this.orderPayload).then(() => {
        this.checkPagination(column);
      });
    },
    cardsEmployee(id) {
      if (this.users.length && id) {
        const currentUser = this.users.filter((item) => item.id === id)[0];
        if (currentUser) {
          return currentUser.name;
        } else {
          return "-";
        }
      }
    },
    onCardDrop(columnList, columnId, dropResult) {
      const colColumn = this.statusIdList.length;
      if (this.dropColumn < colColumn - 1) {
        this.dropColumn++;
      }
      let index = -1;
      if (dropResult.removedIndex !== null) {
        Object.keys(columnList).map((item, indexItem) => {
          if (dropResult.payload.id === columnList[item].id) {
            index = indexItem;
          }
        });
        this.statusIdList[columnId].list.splice(index, 1);
      }
      if (dropResult.addedIndex !== null) {
        this.statusIdList[this.dropColumn].list.splice(
          dropResult.addedIndex,
          0,
          dropResult.payload
        );
        dropResult.payload.order_status_id = this.statusIdList[
          this.dropColumn
        ].id;
        const payload = {
          order_status_id: dropResult.payload.order_status_id,
          orders: [
            {
              id: dropResult.payload.id,
              price: dropResult.payload.total_cost,
            },
          ],
        };
        if (this.validateCountOrder) {
          this.validateShipmentMethodsForOrderStatus(
            dropResult.payload.order_status_id,
            dropResult.payload.id
          );
          this.orderDropChangePosition(payload).then((res) => {
            if (res.result) {
              this.getOrdersList(this.searchQuery);
            }
          });
        }
      }
      if (this.dropColumn >= colColumn - 1) {
        this.dropColumn = -1;
      }
    },
    validateCountOrder() {
      const validate = true;
      return validate;
    },
    validateShipmentMethodsForOrderStatus(statusId, orderId) {
      const orderStatus = this.orderStatusList.data.filter(
        (item) => item.id === statusId
      )[0];
      if (orderStatus.payment_id) {
        this.showOrder(orderId).then(() => {
          this.order.payment_method_id = orderStatus.payment_id;
          this.editOrder({
            id: this.order.id,
            data: this.order,
          });
        });
      }
    },
    getCardPayload(columnId) {
      return (index) => {
        return this.statusIdList.filter((p) => p.id === columnId)[0].list[
          index
        ];
      };
    },
    showOrderPopup(item) {
      this.$emit("changePopup", item);
    },
    combineChecked(orderId) {
      this.changeCombineCheckedList(orderId);
    },
    dragStart(card) {
      // return column + e;
      console.log("card - ", card);
    },
    // log (...params) {
    //     console.log(...params)
    // },
    log() {},
    itemPrice(price) {
      let newPrice = 0;
      price.order_products.map((item) => {
        newPrice += item.price * item.quantity;
      });
      return newPrice;
    },
    validateCount(card, column) {
      if (column.highlight_sufficient_amount_goods) {
        let validCardCount = true;
        card.order_products.map((productItem) => {
          const reserve = productItem.order_product_reserves
            ? productItem.order_product_reserves.quantity
            : 0;
          if (
            Number(productItem.product.quantity) + Number(reserve) <
            Number(productItem.quantity)
          ) {
            validCardCount = false;
          }
        });
        return validCardCount ? "#c2e6c2" : "#ffcccc";
      }
    },
    getOrderData(date) {
      return moment(date).format("DD MM YYYY HH:mm");
    },
    ...mapActions({
      editOrder: "orders/editOrder",
      showOrder: "orders/showOrder",
      orderDropChangePosition: "orders/orderDropChangePosition",
      getOrdersList: "orders/getOrdersList",
      getShipmentMethods: "orders/getShipmentMethods",
      changeOrdersStatus: "orders/changeOrdersStatus",
    }),
    ...mapMutations({
      changeCombineCheckedList: "config/changeCombineCheckedList",
      clearCombineOrders: "config/clearCombineOrders",
      confirmCombineOrders: "config/confirmCombineOrders",
      toggleCombineOrders: "config/toggleCombineOrders",
    }),
  },
  mounted() {
    const width = window.innerWidth;
    if (width <= 1280) {
      this.isMobile = true;
    }
  },
};
